<template>
	<div>
		<div v-if="dl.name == 'Site List'" style="height: 100%;">
			<SiteList />
		</div>
		<div v-if="dl.name == 'Map'">
			<DashMap />
		</div>
		<div v-if="dl.name == 'Network Bandwidth'"><NetworkTrafficGraph /></div>

		<div v-if="dl.name == 'CRC Errors'">
			<NetworksCRC :key="refresh" />
		</div>
		<div v-if="dl.name == 'Network Tree View'" style="height: 100%;">
			<NetworkTreeview />
		</div>
		<div v-if="dl.name == 'Tickets'">
			<TicketsList />
		</div>
		<div v-if="dl.name == 'Site Usage'"><DashSiteUsage /></div>
		<div v-if="dl.name == 'IP SLA Latency'"><DashSWIPSLALatency /></div>
		<div v-if="dl.name == 'IP SLA Availability'"><DashSWIPSLAAvailability /></div>
		<div v-if="dl.name == 'Video'"><DashVideo :streamId="dl.properties.stream" /></div>
	</div>
</template>

<script>
export default {
	props: ['dl'],
	data() {
		return {
			refresh: 0,
		};
	},
	mounted() {
		this.refresh++;
	},
	components: {
		SiteList: () => import('@/view/content/widgets/dashboard/SiteList.vue'),
		DashSiteUsage: () => import('@/view/content/widgets/dashboard/DashSiteUsage.vue'),
		DashSWIPSLALatency: () => import('@/view/content/widgets/dashboard/DashSWIPSLALatency.vue'),
		DashSWIPSLAAvailability: () => import('@/view/content/widgets/dashboard/DashSWIPSLAAvailability.vue'),
		DashMap: () => import('@/view/content/widgets/dashboard/DashMap.vue'),
		NetworkTrafficGraph: () => import('@/view/content/widgets/dashboard/NetworkTrafficGraph.vue'),
		NetworkTreeview: () => import('@/view/content/widgets/dashboard/NetworkTreeview.vue'),
		TicketsList: () => import('@/view/content/widgets/dashboard/TicketsList.vue'),
		NetworksCRC: () => import('@/view/content/widgets/dashboard/NetworksCRC.vue'),
		DashVideo: () => import('@/view/content/widgets/dashboard/DashVideo.vue'),
	},
};
</script>

<style></style>
